import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./App2.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Register from "./components/Register";
import NoteGrid from "./components/NoteGrid";
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ModalLaunch from './components/ModalLaunch';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [items, setItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [tagsNotes, setTagsNotes] = useState([]);
  const [appUser, setAppUser] = useState('');
  const pfad = "https://simonhacks.com"
  const [load, setLoad] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [tagList, setTagList] = useState(tags);
  const [neuesTag, setNeuesTag] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [showNewTag, setShowNewTag] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);
  const handleModal2Close = () => setShowNewTag(false);
  const handleModal2Show = () => setShowNewTag(true);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setAppUser(user.username);
      setCurrentUser(user);
    }
  }, []);

  function newTag() {
    fetch(pfad + ':3001/newTag', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ neuesTag, appUser }),
    })
      .then(response => {
        return response.text()
        //setTagList(response)
      })
      .then(response => {
        fetch(pfad + ':3001/tags', {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appUser }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            setTagList(data);
          });
      })
  }

  const getNotes = () => {
    fetch(pfad + ':3001', {
      method: 'POST',
      body: JSON.stringify({ appUser }),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setTagsNotes(data);
        const itemsGroupedByNoteID = groupItemsByID(data, "noteid");
        setFilteredItems(itemsGroupedByNoteID);
        setItems(itemsGroupedByNoteID);
      })
  }

  const groupItemsByID = (data, noteid) => {
    const unique = data.map(e => e[noteid])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => data[e]).map(e => data[e]);
    return unique;
  };

  useEffect(() => {
    getNotes(appUser);
  }, [appUser, showAllNotes]);

  useEffect(() => {
    function getTags() {
      fetch(pfad + ':3001/tags', {
        method: 'POST',
        body: JSON.stringify({ appUser }),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          setTagList(data)
        });
    }
    getTags();
  }, [appUser]);

  const logOut = () => {
    AuthService.logout();
  };

  const searchFilterHandler = (e) => {
    setSearchInput(e.target.value);
    console.log(searchInput)
    if (!load) {
      setLoad(true)
    }
  }

  useEffect(() => {
    setFilteredItems(items.filter((item) => item.notetext.toLowerCase().includes(searchInput.toLowerCase()) || item.notename.toLowerCase().includes(searchInput.toLowerCase())))
    console.log(filteredItems)
  }, [searchInput]);

  const dropdownFilterHandler = (filter) => {
    setTagFilter(filter.target.text.substring(1))
    if (!load) {
      setLoad(true)
    }
  }

  const showNotesClickHandler = () => {
    setShowAllNotes(!showAllNotes);
  }

  const filterByTag = (tagFilter) => {
    return tagsNotes.filter((item) => item.tagname.toLowerCase().includes(tagFilter.toLowerCase()))
  }

  useEffect(() => {
    setFilteredItems(filterByTag(tagFilter))
  }, [tagFilter])

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          Zettelkasten  
        </Link>
        <div className="navbar-nav mr-auto">
   
          {currentUser && (
            <li className="nav-item">
              <ModalLaunch appUser={appUser} tags={tags} tagList={tagList} setTagList={setTagList} setFilteredItems={setFilteredItems} getNotes={getNotes} pfad={pfad} />
            </li>
            
          )}
          {currentUser && (
              <li className="nav-item">
              <NavDropdown className="dropdownMenu .navbar-nav-scroll" title="Tags" id="basic-nav-dropdown" >
                  {tagList.map(tag => (
                    <NavDropdown.Item className="NavItem" key={tag.tagid} value={tag.tagid} onClick={dropdownFilterHandler}>#{tag.tagname}</NavDropdown.Item>
                    /*  <NavDropdown.Item key={tag.tagid} value={tag.tagid} onClick={e => setTagFilter(tag.tagname)}>#{tag.tagname}</NavDropdown.Item> */
                  ))}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4" onClick={handleModal2Show}>New Tag</NavDropdown.Item>
                  <Modal show={showNewTag} onHide={handleModal2Close}>
                    <Modal.Header>
                      <Form inline>
                        <FormControl type="text" placeholder="new Tag" value={neuesTag} onChange={e => setNeuesTag(e.target.value)} />
                      </Form>
                    </Modal.Header>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleModal2Close}>
                        Close
                </Button>
                      <Button variant="primary" onClick={newTag}>
                        Save new Tag
                </Button>
                    </Modal.Footer>
                  </Modal>
                </NavDropdown>
              </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <Link to={"/notes"} className="nav-link" onClick={showNotesClickHandler}>
                Show notes
                </Link>
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <input type="text" placeholder="Search" className="nav-item-search" onChange={searchFilterHandler} value={searchInput} />
            </li>
          )}
          {currentUser && (
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          )}
        </div>

        {currentUser ? (
          <div>

          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Switch>
          <Route exact path={["/", "/home"]} component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/profile"></Route>
          <Route exact path="/notes">
            <NoteGrid pfad={pfad} className="NoteGrid" getNotes={getNotes} items={filteredItems} filteredItems={filteredItems} setFilteredItems={setFilteredItems} hashtags={tagsNotes} tags={tags} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default App;
