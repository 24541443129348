import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

function ModalLaunch({ tags, setFilteredItems, getNotes, pfad, appUser, tagList, setTagList }) {
/*   const [tagList, setTagList] = useState(tags); */
  const [show, setShow] = useState(false);
  const [noteName, setNoteName] = useState("");
  const [noteText, setNoteText] = useState("");
  const [noteCode, setNoteCode] = useState("");
  const [tag, setTag] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showNoteCode, setShowNoteCode] = useState(false);


  React.useEffect(() => {
    setTagList(tags)
  },[tags],[tagList])

  const handleSave = (e) => {
    setNoteName(noteName);
    setNoteText(noteText);
    setNoteCode(noteCode);
    setTag(tag);
    createNote(noteName, noteText, tag);
  }
  function createNote() {
    //const noteTextFormatted = noteText.replace(/\r?\n/g, '\n');
    fetch(pfad + ':3001/notes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ noteName, noteText, tag, appUser, noteCode }),
    })
      .then(response => {
        getNotes();
        setShow(false);
      })
      .then(
        setShow(false)
      )
  }


  const addTag = (e) => {
    addAdditionalTag(tag)
  }

  function addAdditionalTag(tag){
    fetch(pfad + ':3001/addTag', {
      method: "POST",
      headers:{
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({tag, noteName}),
    })
    .then(response => {
      getNotes();
    })
  }

  return (
    <>
      <a className="nav-link loadNewModal" onClick={handleShow}>
        New Note
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Form inline>
            <FormControl type="text" placeholder="Note Title" className="mr-sm-2" value={noteName} onChange={e => setNoteName(e.target.value)}/>
          </Form>
        </Modal.Header>
        <Modal.Body>
              <textarea placeholder="Note Text" className="noteTextInput" value={noteText} onChange={e => setNoteText(e.target.value)}/>
          {showNoteCode ?  
          (
              <textarea placeholder="Code example" className="noteCodeInput" value={noteCode} onChange={e => setNoteCode(e.target.value)} />
          )
            : (<button className="button" onClick={e => setShowNoteCode(true)}>Enter code</button>)}
            {/* <TagDropdown tags={tags} value={tag} onChange={e => setTag(e.target.value)}/> */}
          <select onChange={e => setTag(e.target.value)}>
            <option key="" value="">Select Tag</option>
            {tagList.map(tag => (
              <option key={tag.tagid} value={tag.tagid} >#{tag.tagname}</option>
            ))}            
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Note
          </Button>
          <Button variant="primary" onClick={addTag}>
            Add another tag
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLaunch;