import React from 'react';

const HastTag = ({ hashtag}) => {
/*     const [tagFilter, setTagFilter] = useState(""); */

  /*   const handleTagFilter = () => {
       console.log(hashtag.tagname)
       setTagFilter(hashtag.tagname)
    } */

    return(
    <div className="hashTag">
            <small>#{hashtag.tagname}</small>
    </div>
    )
}

export default HastTag;
