import React from 'react'
import Note from './Note';
import '../App2.css';


const NoteGrid = ({ items, hashtags, tags, getNotes, setFilteredItems, pfad }) => {
    const filterByTagNote = (tagFilter) => {
        return hashtags.filter((item) => item.tagname.toLowerCase().includes(tagFilter.toLowerCase()))
    }

    return(

    
        <div>   
          {items.map((item) => (
              <Note item={item} key={item.noteid} hashtags={hashtags} tags={tags} pfad={pfad} filterByTagNote={filterByTagNote} setFilteredItems={setFilteredItems} getNotes={getNotes}/>
          ))}
        </div>
 
    )
}
export default NoteGrid
