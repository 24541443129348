import React, { useState } from 'react';
import HashTag from './HashTag'
import Button from 'react-bootstrap/Button';
import ModalLaunchEdit from './ModalLaunchEdit';


const Note = ({ item, hashtags, tags, getNotes, setFilteredItems, filterByTagNote, pfad}) => {
    const [noteid] = useState(item.noteid);
    const noteHashTags = [...hashtags];
    const [imgSource] = useState("https://storageapi.fleek.co/oshiba2207-team-bucket/" + item.ipfshash);
    
    const relHashtags = noteHashTags.filter(hashs => hashs.noteid === item.noteid);
    const deleteHandle = (e) => {
        deleteNote(noteid);
    }

    const deleteNote = (noteid) => {
        
        fetch(pfad + ':3001/deleteNote/' + noteid, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ noteid }),
        })
            .then(response => {
                getNotes();                
            })
    }

    return(
                <div className="noteDiv">
                <p className="noteNameField">{item.notename}</p>
                {item.notetext &&(
                <pre className="noteTextField"><p>{item.notetext}</p></pre>
                )}
                {item.notecode && (
                <code className="noteCodeField">{item.notecode}</code>
                )}
                <a className="imageLink" href={imgSource}
                    target="_blank" rel="noopener noreferrer">
                    <img
                        src={imgSource}
                        loading="lazy"
                        alt="">
                    </img>
                </a>
                <div className="changeButtonDiv">
                <ModalLaunchEdit tags={tags} item={item} getNotes={getNotes} pfad={pfad} />
                <Button size="sm" style={{ margin: 10 }} variant="danger" onClick={deleteHandle} value={item.noteid}>Del</Button>
                </div>
                <div className="hashTag-container">
                {relHashtags.map((hashtag, i) => (
                    <HashTag hashtag={relHashtags[i]} filterByTagNote={filterByTagNote} key={hashtag.linkid} />
                ))}
                </div>
                </div>

   
    )
}

export default Note;